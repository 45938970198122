<vsf-layout>
    <vsf-layout-header>
        <div
            class="bg-gradient-to-r from-zinc-600 to-zinc-800 shadow-lg transform shadow-xl"
        >
            <div
                class="max-w-6xl mx-2 py-2 md:mx-auto flex items-center space-x-4"
            >
                <vsf-mobile-menu-toggle
                    class="md:hidden"
                ></vsf-mobile-menu-toggle>
                <a [routerLink]="['/']" class="l">
                    <img
                        alt="Vendure Storefront"
                        class="w-10"
                        src="assets/cube-logo-small.webp"
                    />
                </a>
                <vsf-collections-menu
                    class="hidden md:block text-white"
                ></vsf-collections-menu>
                <vsf-product-search-bar class="flex-1"></vsf-product-search-bar>
                <vsf-cart-toggle (toggle)="openCartDrawer()"></vsf-cart-toggle>
                <vsf-account-link
                    class="md:block text-white"
                ></vsf-account-link>
            </div>
        </div>
    </vsf-layout-header>
    <vsf-collections-menu-mobile
        [visible]="mobileNavVisible$ | async"
        class="z-10"
    ></vsf-collections-menu-mobile>
    <vsf-cart-drawer
        (close)="closeCartDrawer()"
        [visible]="cartDrawerVisible$ | async"
    ></vsf-cart-drawer>
    <div class="contents">
        <vsf-home-page *ngIf="isHomePage$ | async; else main"></vsf-home-page>
        <ng-template #main>
            <router-outlet></router-outlet>
        </ng-template>
    </div>

    <vsf-layout-footer>
        <footer
            aria-labelledby="footer-heading"
            class="mt-24 border-t bg-gray-50"
        >
            <h2 class="sr-only" id="footer-heading">Footer</h2>
            <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div class="grid grid-cols-2 gap-8 xl:col-span-2">
                        <div class="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3
                                    class="text-sm font-semibold text-gray-500 tracking-wider uppercase"
                                >
                                    Shop
                                </h3>
                                <ul class="mt-4 space-y-4" role="list">
                                    <li
                                        *ngFor="
                                            let item of topCollections$ | async
                                        "
                                    >
                                        <a
                                            [routerLink]="[
                                                '/category',
                                                item.slug
                                            ]"
                                            class="text-base text-gray-500 hover:text-gray-600"
                                        >
                                            {{ item.name }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="mt-12 md:mt-0">
                                <h3
                                    class="text-sm font-semibold text-gray-500 tracking-wider uppercase"
                                >
                                    Support
                                </h3>
                                <ul class="mt-4 space-y-4" role="list">
                                    <li *ngFor="let item of navigation.support">
                                        <a
                                            [href]="item.href"
                                            class="text-base text-gray-500 hover:text-gray-600"
                                        >
                                            {{ item.name }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3
                                    class="text-sm font-semibold text-gray-500 tracking-wider uppercase"
                                >
                                    Company
                                </h3>
                                <ul class="mt-4 space-y-4" role="list">
                                    <li *ngFor="let item of navigation.company">
                                        <a
                                            [href]="item.href"
                                            class="text-base text-gray-500 hover:text-gray-600"
                                        >
                                            {{ item.name }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </vsf-layout-footer>
</vsf-layout>
